<template>
  <div class="shipowner">
    <div class="facilityBox">
      <!-- 状态栏 -->
      <el-tabs
        v-model="activeCmpt"
        @tab-click="resetForm"
      >
        <el-tab-pane
          :label="item.statusName"
          :name="item.status"
          v-for="(item, index) in statusList"
          :key="index"
        ></el-tab-pane>
      </el-tabs>

      <!-- 检索栏 -->
      <el-form
        class="manageForm"
        :model="queryForm"
        :inline="true"
        label-width="140px"
      >
        <el-form-item
          label="是否代收人"
          label-width="96px"
        >
          <el-select
            v-model="queryForm.IsRecipient"
            placeholder="请选择是否代收人"
            @change="search"
          >
            <el-option
              v-for="item in RecipientQueryList"
              :key="item.Code"
              :label="item.Name"
              :value="item.Code"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="企业名称："
          label-width="96px"
        >
          <el-input
            class="fromInp"
            v-model="queryForm.EnterpriseName"
            placeholder="输入企业名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="船东姓名"
          label-width="96px"
        >
          <el-input
            class="fromInp"
            v-model="queryForm.ShipownerName"
            placeholder="输入船东姓名"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="船东身份证号"
          label-width="96px"
        >
          <el-input
            class="fromInp"
            v-model="queryForm.ShipownerIDCard"
            placeholder="输入船东姓名"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="船东手机号"
          label-width="96px"
        >
          <el-input
            class="fromInp"
            v-model="queryForm.TelPhone"
            placeholder="输入船东姓名"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="statusList.find((it) => it.status == activeCmpt).timeTxt + '：'"
          label-width="96px"
        >
          <el-date-picker
            v-model="queryForm.SearchDate"
            type="daterange"
            range-separator="至"
            start-placeholder="请选择开始时间"
            end-placeholder="请选择结束时间"
            value-format="yyyy-MM-dd"
            style="width: 300px"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button
            type="primary"
            size="medium"
            @click="search"
            icon="el-icon-search"
          >搜索</el-button>
          <el-button
            type="primary"
            size="medium"
            icon="el-icon-delete"
            @click="resetForm"
          >清空</el-button>
        </el-form-item>
      </el-form>

      <!-- 表格区域 -->
      <el-table
        :data="tableData"
        :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
        v-loading="loading"
        :key="activeCmpt"
      >
        <el-table-column
          type="index"
          align="center"
          label="序号"
          width="50"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseName"
          label="企业名称"
          show-overflow-tooltip
          width="200"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="ShipownerName"
          label="船东姓名"
          show-overflow-tooltip
          width="200"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="ShipownerIDCard"
          label="船东身份证号"
          show-overflow-tooltip
          width="250"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="TelPhone"
          label="船东手机号"
          show-overflow-tooltip
          width="200"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="IsRecipient"
          label="代收人"
          show-overflow-tooltip
          width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.IsRecipient ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="BankName"
          label="开户银行"
          show-overflow-tooltip
          width="300"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="BankCardNumber"
          label="银行账号"
          show-overflow-tooltip
          width="300"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="StatusTime"
          :label="statusList.find((it) => it.status == activeCmpt).timeTxt"
          width="200"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="Remark"
          label="驳回原因"
          fixed="right"
          width="200"
          show-overflow-tooltip
          v-if="activeCmpt == '1'"
        ></el-table-column>
        <el-table-column
          align="center"
          label="操作"
          fixed="right"
          width="150"
        >
          <template slot-scope="scope">
            <el-button
              type="primary"
              @click="toDetail(scope.row)"
            >{{
              scope.row.Status == 10 ? "审核" : "详情"
            }}</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <div style="margin-top: 10px">
        <el-pagination
          background
          class="pagination"
          @current-change="handleCurrentChange"
          :current-page.sync="queryForm.pageIndex"
          :page-size="queryForm.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 新增/编辑弹框 -->
    <el-dialog
      class="showAddDialog"
      append-to-body
      :visible.sync="showAddDialog"
      width="80%"
      v-if="showAddDialog"
      :close-on-click-modal="false"
    >
      <span
        slot="title"
        class="dialog-title"
      >{{
        dialogType == "detail" ? "查看详情" : !currentItem ? "新增船东" : "编辑船东"
      }}</span>

      <el-row>
        <el-col :span="12">
          <div class="title">证照信息</div>

          <div
            class="content"
            v-if="shipOwnerStatus != 10"
          >
            <div class="upBox">
              <el-image
                :src="driverImg.idFace.picUrl"
                :preview-src-list="srcList"
                class="img"
              >
              </el-image>
              <div class="upText">身份证(正面)</div>
            </div>
            <div class="upBox">
              <el-image
                :src="driverImg.idBack.picUrl"
                :preview-src-list="srcList"
                class="img"
              >
              </el-image>
              <div class="upText">身份证(反面)</div>
            </div>
            <div
              class="upBox"
              v-if="driverImg.SeamanPassportUrl.picUrl"
            >
              <el-image
                :src="driverImg.SeamanPassportUrl.picUrl"
                :preview-src-list="srcList"
                class="img"
              >
              </el-image>
              <div class="upText">船员证</div>
            </div>
            <div
              class="upBox"
              v-if="driverImg.FlightManagementBookUrl.picUrl"
            >
              <el-image
                :src="driverImg.FlightManagementBookUrl.picUrl"
                :preview-src-list="srcList"
                class="img"
              >
              </el-image>
              <div class="upText">船员船务薄</div>
            </div>
          </div>
          <div
            class="content"
            v-if="shipOwnerStatus == 10"
          >
            <div class="upBox">
              <UploadImg
                ref="idFace"
                :loading="driverImg.idFace.loading"
                :uploaded="driverImg.idFace.uploaded"
                :picUrl="driverImg.idFace.picUrl"
                @update:file="updateFile"
                :fileType="'Ship'"
                @change="uploadIdFace(driverImg.idFace)"
                @deleteImg="deleteImg(driverImg.idFace)"
                v-if="dialogType != 'detail'"
              >
              </UploadImg>
              <el-image
                :src="driverImg.idFace.picUrl"
                :preview-src-list="srcList"
                class="img"
                v-else
              >
              </el-image>
              <div class="upText">
                <span class="required">*</span>
                身份证(正面)
                <i
                  @click="imgCase(1)"
                  class="el-icon-question"
                  style="cursor: pointer; color: rgb(230, 160, 62)"
                >
                  案例
                </i>
              </div>
            </div>
            <div class="upBox">
              <UploadImg
                ref="idBack"
                :loading="driverImg.idBack.loading"
                :uploaded="driverImg.idBack.uploaded"
                :picUrl="driverImg.idBack.picUrl"
                @update:file="updateFile"
                :fileType="'Ship'"
                @change="uploadIdBack(driverImg.idBack)"
                @deleteImg="deleteImg(driverImg.idBack)"
                v-if="dialogType != 'detail'"
              >
              </UploadImg>
              <el-image
                :src="driverImg.idBack.picUrl"
                :preview-src-list="srcList"
                class="img"
                v-else
              >
              </el-image>
              <div class="upText">
                <span class="required">*</span>
                身份证(反面)
                <i
                  @click="imgCase(2)"
                  class="el-icon-question"
                  style="cursor: pointer; color: rgb(230, 160, 62)"
                >
                  案例
                </i>
              </div>
            </div>
            <div class="upBox">
              <UploadImg1
                ref="SeamanPassportUrl"
                :loading="driverImg.SeamanPassportUrl.loading"
                :uploaded="driverImg.SeamanPassportUrl.uploaded"
                :picUrl="driverImg.SeamanPassportUrl.picUrl"
                @update:file="updateFile"
                @change="uploadCertificate(driverImg.SeamanPassportUrl)"
                @deleteImg="deleteImg(driverImg.SeamanPassportUrl)"
                v-if="dialogType != 'detail'"
              >
              </UploadImg1>
              <el-image
                :src="driverImg.SeamanPassportUrl.picUrl"
                :preview-src-list="srcList"
                class="img"
                v-else
              >
              </el-image>
              <div class="upText">
                <span
                  class="required"
                  v-show="!addForm.IsRecipient"
                >*</span>船员证
              </div>
            </div>
            <div class="upBox">
              <UploadImg1
                ref="FlightManagementBookUrl"
                :loading="driverImg.FlightManagementBookUrl.loading"
                :uploaded="driverImg.FlightManagementBookUrl.uploaded"
                :picUrl="driverImg.FlightManagementBookUrl.picUrl"
                @update:file="updateFile"
                @change="uploadCertificate(driverImg.FlightManagementBookUrl)"
                @deleteImg="deleteImg(driverImg.FlightManagementBookUrl)"
                v-if="dialogType != 'detail'"
              >
              </UploadImg1>
              <el-image
                :src="driverImg.FlightManagementBookUrl.picUrl"
                :preview-src-list="srcList"
                class="img"
                v-else
              >
              </el-image>
              <div class="upText">
                <span
                  class="required"
                  v-show="!addForm.IsRecipient"
                >*</span>船员船务薄
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="title">基本资料</div>
          <div class="content">
            <el-form
              class="addForm"
              :model="addForm"
              :rules="addRule"
              ref="addForm"
              :disabled="dialogType == 'detail'"
            >
              <el-form-item
                label="船东姓名"
                prop="ShipownerName"
              >
                <el-input
                  v-model="addForm.ShipownerName"
                  placeholder="输入船东姓名"
                  maxlength="32"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="船东身份证号"
                prop="ShipownerIDCard"
              >
                <el-input
                  v-model="addForm.ShipownerIDCard"
                  placeholder="输入船东身份证号"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="船东手机号"
                prop="TelPhone"
              >
                <el-input
                  v-model="addForm.TelPhone"
                  placeholder="输入船东手机号"
                ></el-input>
              </el-form-item>
              <!-- <el-form-item label="身份证有效期" prop="EquipOwner" label-width="120px">
                            <el-input v-model="addForm.EquipOwner" disabled placeholder="输入身份证有效期"></el-input>
                        </el-form-item> -->
              <el-form-item
                label="开户银行"
                prop="BankName"
              >
                <el-input
                  v-model="addForm.BankName"
                  placeholder="输入开户银行"
                  maxlength="50"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="银行账号"
                prop="BankCardNumber"
              >
                <el-input
                  v-model="addForm.BankCardNumber"
                  placeholder="输入银行账号"
                  maxlength="32"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="是否代收人"
                prop="IsRecipient"
              >
                <el-switch
                  v-model="addForm.IsRecipient"
                  active-color=""
                  active-text="是"
                  inactive-text="否"
                  inactive-color="#ff4949"
                >
                </el-switch>
              </el-form-item>
            </el-form>
          </div>
          <div class="body">
            <div class="title">
              关联船舶
              <div style="display: inline-block; color: red; cursor: pointer">
                注明：船东可关联多艘船舶，船东无船可不填
              </div>
            </div>
            <div>
              <el-button
                type="primary"
                @click="bindShiptDialog = true"
                icon="el-icon-truck"
              >关联船舶</el-button>
            </div>
            <div class="content">
              <el-table
                :data="bindShipList"
                v-loading="bindshipLoading"
                :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
                max-height="550"
                style="margin: 16px 0"
              >
                <el-table-column
                  fixed
                  align="center"
                  type="index"
                  label="序号"
                  width="50"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="ShipNO"
                  label="船舶编号"
                >
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="ShipName"
                  width="120"
                  label="船舶名称"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="ShipRegNumber"
                  label="船舶登记号"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="BindTime"
                  label="绑定时间"
                ></el-table-column>
              </el-table>
            </div>
          </div>
        </el-col>
      </el-row>

      <div
        slot="footer"
        v-if="dialogType != 'detail'"
      >
        <el-button
          size="medium"
          @click="
            () => {
              showAddDialog = !showAddDialog;
              shipPage();
            }
          "
        >关闭</el-button>
        <el-button
          type="primary"
          size="medium"
          @click="addShip()"
          :loading="loading"
        >保存资料</el-button>
        <el-button
          type="success"
          @click="toAgree"
        > 认证通过并下一个</el-button>
        <el-button
          type="warning"
          @click="toNext"
          icon="el-icon-star-off"
        >
          查看下一个</el-button>
        <el-button
          type="danger"
          @click="toReject"
          icon="el-icon-close"
        >驳回通过并下一个
        </el-button>
      </div>
      <div
        slot="footer"
        v-else
      >
        <el-button
          size="medium"
          @click="showAddDialog = !showAddDialog"
        >关闭</el-button>
      </div>
    </el-dialog>

    <!-- 查看账户案例 -->
    <el-dialog
      class="addDialog"
      :visible.sync="isCase"
      width="840px"
      :close-on-click-modal="false"
    >
      <span
        slot="title"
        class="dialog-title"
      > 查看案例 </span>
      <div
        v-for="(item, index) in caselist"
        :key="index"
      >
        <img
          style="width: 800px"
          :src="item.SampleURL"
        />
        <div style="width: 100; text-align: center; font-size: 16px; color: #000">
          {{ item.Name }}
        </div>
      </div>
    </el-dialog>
    <!-- 拒绝原因dialog -->
    <el-dialog
      width="700px"
      title="驳回原因"
      :visible.sync="rejectModal"
      append-to-body
    >
      <el-input
        type="textarea"
        maxlength="200"
        placeholder="请输入驳回原因，限制200个汉字"
        v-model="remark"
      ></el-input>
      <div style="display: flex; justify-content: center; margin-top: 16px">
        <el-button
          size="mini"
          @click="rejectModal = false"
        >取消</el-button>
        <el-button
          type="primary"
          :loading="loading"
          size="mini"
          @click="sendBack"
          :disabled="!remark"
        >确定
        </el-button>
      </div>
    </el-dialog>

    <!-- 关联车辆 -->
    <el-dialog
      class="addDialog"
      :visible.sync="bindShiptDialog"
      width="740px"
      :close-on-click-modal="false"
    >
      <span
        slot="title"
        class="dialog-title"
      > 关联船舶 </span>
      <div style="width: 100%; text-align: center; padding: 0 0 10px 0">
        船舶名称：
        <el-input
          style="width: 300px"
          v-model="bindShipQuery.ShipName"
          maxlength="10"
          placeholder="请输入船舶名称"
        ></el-input>
        <el-button
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-circle-plus-outline"
          @click="addBindShip()"
        >
          添加船舶</el-button>
      </div>
      <el-table
        :data="bindShipList"
        :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
        max-height="550"
      >
        <el-table-column
          fixed
          align="center"
          type="index"
          label="序号"
          width="50"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="ShipNO"
          label="船舶编号"
        > </el-table-column>
        <el-table-column
          align="center"
          prop="ShipName"
          width="120"
          label="船舶名称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="ShipRegNumber"
          label="船舶登记号"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="BindTime"
          label="绑定时间"
        ></el-table-column>
        <el-table-column
          align="center"
          label="操作"
          width="100"
        >
          <template slot-scope="scope">
            <el-button
              @click="delBindShip(scope.row)"
              type="danger"
              size="small"
              title="删除"
              icon="el-icon-delete"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        background
        class="pagination"
        @current-change="bindShipCurrentChange"
        :current-page.sync="bindshipPagination.page"
        :page-size="bindshipPagination.pagesize"
        layout="total, prev, pager, next, jumper"
        :total="bindshipPagination.total"
      >
      </el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import UploadImg from "@/components/commonCmpt/cropperImage";
import UploadImg1 from "@/components/commonCmpt/uploadImg";
import { GetSampleInfo, upLoadImgByUrl } from "@/api/common/common";
import { uploadIdFace, uploadIdBack } from "@/api/transport/driverManage/add";
import {
  ShipOwnerPage,
  ShipOwnerEdit,
  ShipOwnerDetail,
  ShipOwnerAgree,
  ShipOwnerReject,
  RelationBindCheck,
  RelationBindDel,
  RelationBindPage,
  PageStatus,
} from "@/api/transport/ship/shipOwner";
export default {
  data() {
    return {
      dialogLoading: false,
      //分页控件相关参数
      pagination: {
        total: 0, //总条数
      },
      loading: false, //表格loading
      activeCmpt: "0",
      statusList: [
        {
          statusName: "待提交",
          status: "0",
          timeTxt: "添加时间",
        },
        {
          statusName: "待审核",
          status: "10",
          timeTxt: "提交时间",
        },
        {
          statusName: "已通过",
          status: "100",
          timeTxt: "审核时间",
        },
        {
          statusName: "已驳回",
          status: "1",
          timeTxt: "驳回时间",
        },
      ],
      showAddDialog: false, //弹框
      currentFile: "", //当前正在操作的文件
      dialogType: "", // 弹窗类型
      // 案例
      isCase: false,
      // 案例列表
      caselist: [],
      //筛选条件
      queryForm: {
        TelPhone: "",
        ShipownerName: "",
        ShipownerIDCard: "",
        StatusTimeStart: "",
        StatusTimeEnd: "",
        EnterpriseName: "",
        SearchDate: ["", ""],
        AscriptionUserID: "", //当前登录的用户Id
        IsRecipient: null,
        pageIndex: 1, //当前所处的页码
        pageSize: 10, //每次请求的数量
      },

      // 表格数据
      tableData: [],
      multipleSelection: [],
      //提交表单
      addForm: {
        AscriptionUserID: 0,
        TelPhone: "",
        ShipownerName: "",
        ShipownerIDCard: "",
        BankName: "",
        BankCardNumber: "",
        ID: null,
        IsRecipient: null,
      },
      shipOwnerStatus: 0,
      // 表单校验
      addRule: {
        TelPhone: [
          { required: true, message: "请输入船东手机号", trigger: "change" },
          {
            required: true,
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        ShipownerName: [{ required: true, message: "请输入船东姓名", trigger: "change" }],
        ShipownerIDCard: [
          { required: true, message: "请输入船东身份证号", trigger: "change" },
          {
            pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
            message: "请输入正确的身份证号",
            trigger: "blur",
          },
        ],
      },
      //人员证件照片
      driverImg: {
        idFace: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        idBack: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        SeamanPassportUrl: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        FlightManagementBookUrl: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
      },
      currentItem: "", // 当前操作数据
      srcList: [],
      rejectModal: false,
      remark: "",
      //角色属性
      RecipientQueryList: [
        {
          Code: null,
          Name: "全部",
        },
        {
          Code: true,
          Name: "是",
        },
        {
          Code: false,
          Name: "否",
        },
      ],

      bindShiptDialog: false, //关联船舶弹窗
      bindShipList: [], //关联船舶数据
      bindshipLoading: false,
      // 关联船舶分页参数
      bindshipPagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      bindShipQuery: {
        ShipName: "",
      },
    };
  },
  components: {
    UploadImg,
    UploadImg1,
  },
  created() {
    this.shipPage();
  },
  methods: {
    getPageStatus({ thereIsCurrentlyNoNextItemAvailableCallback }) {
      var index = this.tableData.findIndex((x) => x.ID == this.currentItem.ID);
      if (index + 1 == this.tableData.length) {
        //最后一条
        this.showAddDialog = false;
        this.$message.warning("暂无下一条");
        setTimeout(() => {
          this.shipPage();
          thereIsCurrentlyNoNextItemAvailableCallback();
        }, 1000);
      } else {
        // this.$message.success("跳转下一个成功");
        this.currentItem = this.tableData[index + 1];
        setTimeout(() => {
          this.detailShip(
            this.currentItem.Status != 10 ? "detail" : "edit",
            this.currentItem.ID
          );
        }, 1000);
      }
      // let data = {
      //   ShipType: this.queryForm.ShipType,
      //   ShipNO: this.queryForm.ShipNO,
      //   ShipRegNumber: this.queryForm.ShipRegNumber,
      //   EnterpriseName: this.queryForm.EnterpriseName,
      //   ShipName: this.queryForm.ShipName,
      //   status: Number(this.activeCmpt),
      //   statusTimeStart:
      //     this.queryForm.searchDate &&
      //     this.queryForm.searchDate[0] &&
      //     this.queryForm.searchDate[0] + " 00:00:00",
      //   statusTimeEnd:
      //     this.queryForm.searchDate &&
      //     this.queryForm.searchDate[1] &&
      //     this.queryForm.searchDate[1] + " 23:59:59",
      // };
      // PageStatus(data).then((res) => {
      //   if (res.data == null) {
      //     this.$message.success("暂无下一个");
      //     this.showAddDialog = false;
      //   } else {
      //     this.$message.success("跳转下一个成功");
      //     this.currentItem = res.data;
      //     this.detailShip(
      //       this.currentItem.Status != 10 ? "detail" : "edit",
      //       this.currentItem.ID
      //     );
      //   }
      // });
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.queryForm.pageIndex = e;
      this.shipPage();
    },
    // 多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //上传证件
    uploadCertificate(field) {
      field.loading = true;
      var reader = new FileReader();
      reader.readAsDataURL(this.currentFile);
      reader.onload = () => {
        upLoadImgByUrl({ FileBase64: reader.result, FileType: "Ship" })
          .then((res) => {
            field.loading = false;
            field.uploaded = true;
            if (res.data) {
              field.picUrl = res.data;
            }
          })
          .catch(() => { })
          .finally(() => {
            field.loading = true;
            field.uploaded = true;
          });
      };
    },
    //设置当前组件正在操作的文件
    updateFile(file) {
      this.currentFile = file;
    },
    //删除图片
    deleteImg(field) {
      field.uploaded = false;
      field.loading = false;
      field.picUrl = "";
    },

    // 查看案例
    imgCase(SampleType) {
      this.caselist = [];
      GetSampleInfo({ SampleType }).then((res) => {
        this.caselist = res.data;
        this.isCase = true;
      });
    },
    toNext() {
      const loading = this.$loading({
        lock: true,
        text: "请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.getPageStatus({
        thereIsCurrentlyNoNextItemAvailableCallback: () => {
          loading.close();
        },
      });
    },
    // 通过审核
    toAgree() {
      this.$confirm("确定通过审核吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: "请求中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });

        ShipOwnerAgree({ id: this.currentItem.ID })
          .then((res) => {
            this.$message.success("操作成功");
            this.getPageStatus({
              thereIsCurrentlyNoNextItemAvailableCallback: () => {
                loading.close();
              },
            });
            // this.showAddDialog = false;
          })
          .finally(() => {
            loading.close();
          });
      });
    },
    // 驳回
    toReject() {
      this.rejectModal = true;
      this.remark = "";
    },
    sendBack() {
      this.$confirm("确定驳回吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: "请求中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        ShipOwnerReject({ id: this.currentItem.ID, remark: this.remark })
          .then((res) => {
            this.$message.success("操作成功");
            this.rejectModal = false;
            this.getPageStatus({
              thereIsCurrentlyNoNextItemAvailableCallback: () => {
                loading.close();
              },
            });
            // this.showAddDialog = false;
            // this.shipPage();
          })
          .finally(() => {
            loading.close();
          });
      });
    },
    //上传身份证正面
    uploadIdFace(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadIdFace(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          field.picUrl = res.drivers.idFaceUrl;
          //赋值表单
          this.addForm.ShipownerName = res.drivers.name;
          this.addForm.ShipownerIDCard = res.drivers.num;
        })
        .catch(() => {
          this.deleteImg(field);
        })
        .finally(() => {
          this.$refs.idFace.dialog();
        });
    },

    //上传身份证反面
    uploadIdBack(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadIdBack(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          field.picUrl = res.drivers.idBackUrl;
        })
        .catch(() => {
          this.deleteImg(field);
        })
        .finally(() => {
          this.$refs.idBack.dialog();
        });
    },

    //列表
    shipPage() {
      this.queryForm.Status = Number(this.activeCmpt);
      this.loading = true;
      ShipOwnerPage({
        ...this.queryForm,
        StatusTimeStart:
          this.queryForm.SearchDate &&
          this.queryForm.SearchDate[0] &&
          this.queryForm.SearchDate[0] + " 00:00:00",
        StatusTimeEnd:
          this.queryForm.SearchDate &&
          this.queryForm.SearchDate[1] &&
          this.queryForm.SearchDate[1] + " 23:59:59",
      })
        .then((res) => {
          this.tableData = res.data.DataList;
          this.pagination.total = Number(res.data.TotalCount);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    //检索
    search() {
      this.queryForm.pageIndex = 1;
      this.shipPage();
    },

    //清空检索条件
    resetForm() {
      this.queryForm = {};
      this.queryForm.pageIndex = 1;
      this.queryForm.pageSize = 10;
      this.queryForm.SearchDate = ["", ""];
      this.shipPage();
    },

    //新增/编辑
    addShip() {
      if (!this.driverImg.idFace.picUrl) {
        this.$message.warning("请上传身份证正面");
        return;
      }
      if (!this.driverImg.idBack.picUrl) {
        this.$message.warning("请上传身份证反面");
        return;
      }
      if (!this.driverImg.SeamanPassportUrl.picUrl && !this.addForm.IsRecipient) {
        this.$message.warning("请上传船员证");
        return;
      }
      if (!this.driverImg.FlightManagementBookUrl.picUrl && !this.addForm.IsRecipient) {
        this.$message.warning("请上传船员船务薄");
        return;
      }
      this.addForm.id = this.addForm.ID;
      delete this.addForm.ID;
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          ShipOwnerEdit({
            ...this.addForm,
            IdCardFrontUrl: this.driverImg.idFace.picUrl,
            IdCardBackUrl: this.driverImg.idBack.picUrl,
            flightManagementBookUrl: this.driverImg.FlightManagementBookUrl.picUrl,
            seamanPassportUrl: this.driverImg.SeamanPassportUrl.picUrl,
          })
            .then((res) => {
              this.$message.success("操作成功");
              this.detailShip("edit", this.currentItem.ID);
              this.shipPage();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },

    //详情
    detailShip(type, id) {
      this.dialogType = type;
      const loading = this.$loading({
        lock: true,
        text: "请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.srcList = [];
      ShipOwnerDetail({ id })
        .then((res) => {
          const {
            ID,
            TelPhone,
            ShipownerName,
            ShipownerIDCard,
            BankName,
            BankCardNumber,
            IsRecipient,
          } = JSON.parse(JSON.stringify(res.data));
          this.addForm = {
            ID,
            TelPhone,
            ShipownerName,
            ShipownerIDCard,
            BankName,
            BankCardNumber,
            IsRecipient,
          };
          this.driverImg.idFace.picUrl = res.data.IDCardFrontUrl;
          this.driverImg.idBack.picUrl = res.data.IDCardBackUrl;
          this.driverImg.FlightManagementBookUrl.picUrl =
            res.data.FlightManagementBookUrl;
          this.driverImg.SeamanPassportUrl.picUrl = res.data.SeamanPassportUrl;
          this.srcList.push(
            res.data.IDCardFrontUrl,
            res.data.IDCardBackUrl,
            res.data.SeamanPassportUrl,
            res.data.FlightManagementBookUrl
          );
          this.shipOwnerStatus = res.data.Status;
          this.showAddDialog = true;
        })
        .finally(() => {
          loading.close();
          this.pageBindShip();
        });
    },
    // 查看详情
    toDetail(item) {
      this.currentItem = item;
      this.detailShip(item.Status != 10 ? "detail" : "edit", item.ID);
    },

    addBindShip() {
      if (this.bindShipQuery.ShipName == "") {
        this.$message({
          message: "请输入船舶名称进行添加",
          type: "warning",
        });
        return;
      }

      //车牌校验
      RelationBindCheck({
        ShipownerIDCard: this.currentItem.ShipownerIDCard,
        CarNumber: this.bindShipQuery.ShipName,
      })
        .then((res) => {
          //相同船舶校验
          let shipany = false;
          this.bindShipList.forEach((item) => {
            if (item.ShipName == this.bindShipQuery.ShipName) {
              shipany = true;
            }
          });
          if (shipany) {
            this.$message.error("该船舶已存在列表，请勿重复添加。");
            return;
          }

          this.bindShipList.push(res.data);
        })
        .finally(() => {
          this.bindShipQuery.ShipName = "";
        });
    },

    delBindShip(item) {
      this.$confirm("是否删除该船舶?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        RelationBindDel({
          ShipownerIDCard: this.currentItem.ShipownerIDCard,
          CarNumber: item.ShipName,
        }).then((res) => {
          this.bindShipList.forEach((data, index) => {
            if (data.ShipName == item.ShipName) {
              this.bindShipList.splice(index, 1);
            }
          });
        });
      });
    },

    bindShipCurrentChange(e) {
      this.pagination.page = e;
    },

    pageBindShip() {
      var param = {
        ShipownerIDCard: this.currentItem.ShipownerIDCard,
        UserId: this.currentItem.AscriptionUserID,
      };
      RelationBindPage(param).then((res) => {
        this.bindShipList = res.data.DataList;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.shipowner {
  .topbtns {
    padding-bottom: 10px;
    margin: 10px 0px;
    border-bottom: 1px solid #eee;
  }
}

.showAddDialog {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }

  ::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
    text-align: center;
  }

  ::v-deep .el-dialog__body {
    padding: 20px;
  }

  .title {
    color: #2081ff;
    position: relative;
    text-indent: 15px;
    font-size: 14px;
    margin-bottom: 10px;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 3px;
      height: 14px;
      border-radius: 5px;
      background: #2081ff;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .upBox {
      text-align: center;

      .upText {
        font-size: 14px;
        font-weight: bold;
        margin: 10px 0 0 0;
        color: #333333;
        display: flex;
        justify-content: center;
        align-content: center;
        .required {
          bottom: 0px;
          line-height: normal;
        }
      }
    }

    .el-upload__tip {
      color: #7e7e7e;
    }
  }

  .img {
    width: 278px;
    height: 174px;
    border-radius: 10px;
  }

  .addForm {
    display: flex;
    // flex-direction: column;
    flex-wrap: wrap;
  }

  ::v-deep .el-form-item__content {
    margin-right: 20px !important;

    .el-input__inner,
    .el-date-editor {
      width: 200px;
    }
  }
}
</style>
